<template>
	<div v-show="!showModal && !showGrantRecord">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message:'必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="(id) => getAllCinemaList(id, true)">
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row v-show="showAll">
					<a-form-item label="活动名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入活动名称"></a-input>
					</a-form-item>

					<a-form-item label="兑换劵批次" name="batchNo" class="ui-form__item">
						<a-input v-model:value="formState.batchNo" placeholder="请输入兑换劵批次"></a-input>
					</a-form-item>

					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" style="width: 180px;" placeholder="请选择状态">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="券类型" name="couponType" class="ui-form__item">
						<a-select v-model:value="formState.couponType" style="width: 180px;" placeholder="请选择券类型">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">兑换券</a-select-option>
							<a-select-option :value="3">卖品券</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="活动开始时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>

					<!-- <a-form-item label="活动结束时间" class="ui-form__item">
						<a-range-picker v-model:value="time1"></a-range-picker>
					</a-form-item> -->
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="primary" style="margin-right: 10px;" @click="onAdd">新增推广券活动</a-button>

						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :scroll="{ x: 2000}">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.activityStartTime, 1) }} ~ {{ transDateTime(record.activityEndTime, 1) }}
						</template>
						<template v-if="column.key === 'couponType'">
							<div v-if="record.couponType === 1">兑换券</div>
							<div v-else-if="record.couponType === 3">卖品券</div>
							<div v-else>--</div>
						</template>
						<template v-if="column.key === 'barcode'">
							<span :id="'copyed' + index">{{ record.barcode }}</span>
						</template>
						<template v-if="column.key === 'surplusNum'">
							{{ record.issuedNum - record.receiveNum }}
						</template>

						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>

						<template v-if="column.key === 'action'">
							<a-dropdown v-if="record.userCouponStatus !== 2" :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<!-- <div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div> -->
										<div v-permission="['operation_extension_list_issued']" @click="onRecord(record)">
											<a-menu-item>
												查看发放信息
											</a-menu-item>
										</div>
										<div v-permission="['operation_extension_list_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
											<a-menu-item>启用</a-menu-item>
										</div>
										<div v-permission="['operation_extension_list_disabled']" v-else @click="onDisabled(record)">
											<a-menu-item>禁用</a-menu-item>
										</div>
										<div v-permission="['operation_extension_list_edit']" v-if="record.isDisabled" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['operation_extension_list_qr']" @click="getShareQR(record, 1)">
											<a-menu-item>
												微信推广券二维码
											</a-menu-item>
										</div>
<!--										<div v-permission="['operation_extension_list_qr']" @click="getShareQR(record, 2)">
											<a-menu-item>
												抖音推广券二维码
											</a-menu-item>
										</div>-->
										<div v-permission="['operation_extension_list_delete']" v-if="record.isDisabled" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<span v-else>--</span>
						</template>
					</template>
				</a-table>
			</div>
			<a-modal v-model:visible="visible" title="活动推广二维码" width="500px">
				<template #footer>
					<a-button type="primary" @click="visible = false;">关闭</a-button>
				</template>
				<div>活动名称：{{ title || '-' }}</div>
				<div style="text-align: center;">
					<a-image style="width: 300px;" :src="imgUrl"></a-image>
				</div>
			</a-modal>
		</a-spin>
	</div>
	<temp v-if="showModal" :id="id" :isEdit="isEdit" :isSee="isSee" @back="onBack"></temp>
	<grantRecord v-if="showGrantRecord" :id="id" @back="onBack"></grantRecord>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import grantRecord from './record.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getPromotionActivitie, activePullOnShelves, activePullOffShelves, deleteActive, getShareQR } from '@/service/modules/advert.js';
	export default {
		components: {
			Icon,
			temp,
			grantRecord
		},
		data() {
			return {
				loading: false,
				showAll: false,
				formState: {
					cinemaId: 0,
					couponType: '',
					isDisabled: '',
				},
				time: [],
				time1: [],
				searchData: {},
				organizationList: [],
				cinemaAllList: [],
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '活动名称',
					dataIndex: 'title'
				}, {
					title: '推广券类型',
					key: 'couponType',
					width: 130
				}, {
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '发放量',
					dataIndex: 'issuedNum'
				}, {
					title: '批次剩余张数',
					key: 'surplusNum'
				}, {
					title: '已领取数量',
					dataIndex: 'receiveNum'
				}, {
					title: '有效期',
					key: 'time',
					width: 210
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 130
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 190
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 160
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10','20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				id: 0,
				isSee: false,
				isEdit: false,
				showGrantRecord: false,
				visible: false,
				imgUrl: '',
				title: ''
			}
		},
		created() {
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				this.showGrantRecord = false;
				if (isRef) {
					this.getData();
				}
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.couponType = this.searchData.couponType ? this.searchData.couponType : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled != '' ? this.searchData.isDisabled : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('day').unix();
					this.searchData.endTime = this.time[1].endOf('day').unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.time = [];
				this.time1 = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.list = [];
				this.searchData = {};
				this.pagination.total = 0;
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPromotionActivitie({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						couponType: 1,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					this.loading = false;
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getShareQR(item, source) {
				this.loading = true;
				try {
					let ret = await getShareQR({
						page: 'pages/enterIndex/index',
						scene: `path=4&id=${item.id}`,
						source
					})
					this.loading = false;
					if (ret.code === 200) {
						this.title = item.title;
						this.imgUrl = ret.data.shareQR;
						this.visible = true;
					}
				} catch(e) {
					console.log(e)
					this.loading = false;
				}
			},
			onSee(item) {
				this.id = item.id;
				this.isEdit = false;
				this.isSee = true;
				this.showModal = true;
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.showModal = true;
			},
			onRecord(item) {
				this.id = item.id;
				this.showGrantRecord = true;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该活动吗？',
					onOk: async () => {
						this.loading = true;
						let ret;
						try {
							if (item.isDisabled === 0) {
								ret = await activePullOffShelves({
									id: item.id
								})
							} else {
								ret = await activePullOnShelves({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							console.log(e)
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该活动吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteActive({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
							console.log(e)
						}
					}
				})
			},
			disabledDate(current) {
				return current && current < this.moment().endOf('day');
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
